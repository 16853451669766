export default {
  heading: 'Vujahday Script, cursive',
  body: 'Noto Serif Display, serif',
  monospace: 'Menlo, monospace',
  display: 'Almond Regular, serif',
  display2: 'Launsela Regular, sans-serif',
  display3: 'Averia Serif Libre',
  googleFonts: [
    'Cormorant Garamond:100,200,300,400,500,600,700,800,900',
    'Open Sans:100,200,300,400,500,600,700,800,900',
    'Averia Serif Libre:300,400,500,600,700',
    'Noto Serif Display:100,200,300,400,500,600,700,800,900',
    'Kalam: 300',
    'Vujahday Script'
  ],
  customFamilies: ['Almond Regular', 'Launsela Regular', 'Pesto'],
  customUrls: [
    'https://www.gonation.biz/fonts/almond-regular/almond-regular.css',
    'https://www.gonation.biz/fonts/launsela-regular/launsela-regular.css',
    'https://www.gonation.biz/fonts/pesto/pesto.css'
  ]
}
